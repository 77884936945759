<script lang="ts">
  export let openNavState;
  const navigation = document.getElementById('mainNavigation');
  const navChildren = navigation.children;
  navigation.remove();
</script>

<div class="menu-container relative overflow-hidden">
  <ul id="mainNavigation" role="menu" aria-labelledby="navToggleBtn" class:is-open={openNavState}>
    {#each navChildren as child}
      <li class="{child.classList.value}">{@html child.innerHTML}</li>
    {/each}
  </ul>
</div>

<style lang="scss">
  ul {
    @apply pt-4 md:pt-[3px] pb-12 w-full bg-white overflow-hidden mt-[-250px] transition-all duration-500 ease-in-out uppercase md:text-right;
    &.is-open {
      @apply mt-0;
    }
  }
  li {
    @apply block relative pt-1;
    &:first-of-type::before,
    &:last-of-type::before {
      content: '';
      @apply absolute top-0 left-0 w-24 border-t border-black;
      @screen md {
        left: inherit;
        @apply right-0 h-0 w-[70%];
      }
    }
    &:nth-of-type(3) {
      @apply pb-1;
    }
  }

  :global {
    #mainNavigation {
      a {
        @apply relative;
        line-height: 1;
        font-size: 1rem;
      }

      a:hover::before,
      a:hover::after,
      .current a::before,
      .current a::after {
        position: relative;
        font-size: 1.2rem;
        line-height: 1;
        top: 0.1rem;
      }

      a:hover::after,
      .current a::after {
        content: '\2022';
        padding-left: 5px;
      }

      a:hover::before,
      .current a::before {
        content: '';
        padding: 0;
      }

      @screen md {
        a:hover::after,
        .current a::after {
          content: '';
          padding: 0;
        }
        a:hover::before,
        .current a::before {
          content: '\2022';
          padding-right: 5px;
        }
      }
    }
  }
</style>
