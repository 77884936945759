<script lang="ts">
  import NavToggle from "./NavToggle.svelte";
  import Navigation from "./Navigation.svelte";

  let w = window.innerWidth;
  let openNavState = false;

  const handleOpenNavState = (event) => {
    openNavState = event.detail.state;
  }

  const handleResizeWidth = () => {
    w = window.innerWidth;
    if (w >= 768) openNavState = true;
    if (w < 768) openNavState = false;
  }

  handleResizeWidth();
</script>
<svelte:window on:resize={handleResizeWidth} bind:innerWidth={w}/>
<NavToggle on:openNavState={handleOpenNavState} />
<Navigation {openNavState} />
